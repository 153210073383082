<template>
  <RegisterWithGoogleForm :error="error" @register-with-google-form="registerWithGoogle"/>
</template>

<script>
import RegisterWithGoogleForm from "./RegisterWithGoogleForm.vue"
import { beanieSDK } from "../../vuex/store"

export default {
  components: {
    RegisterWithGoogleForm
  },

  props: ['contained'],

  data() {
    return {
      error: ''
    }
  },

  methods: {
    async registerWithGoogle(accessToken) {
      try {
        const { token, account } = await beanieSDK.signUpWithGoogle({ accessToken, sessionLocale: this.$store.getters.sessionLocale })
        this.$store.dispatch('SET_AUTHENTICATION_DATA', {token, account})

        if (this.contained) {
          this.$emit('signed-up')
        } else {
          await this.$router.push('/')
        }
      } catch (e) {
        this.error = e.message
      }
    }
  }

}
</script>