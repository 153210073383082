<template>
  <div v-if="edit">
    <select @change="setLocale" id="locale" v-model="locale">
      <option :value="locale">Select session language</option>
      <option v-for="[localeValue, label] in Object.entries(localeOptions)" v-bind:key="localeValue" :value="localeValue">{{ label }}</option>
    </select>
  </div>
  <div v-else>
    <span title="Select session language" @click="toggleEdit" style="cursor: pointer">{{ localeOptions[sessionLocale] || localeOptions.default }}</span>
  </div>
</template>

<script>
import {beanieSDK} from "@/vuex/store";
import {LOCALE_OPTIONS} from "@/constants"

export default {
  name: 'Locale',
  data: function() {
    return {
      edit: false,
      locale: this.$store.getters.sessionLocale,
      localeOptions: LOCALE_OPTIONS
    }
  },
  computed : {
    sessionLocale: function() {
      return this.$store.getters.sessionLocale
    }
  },
  methods: {
    toggleEdit: function() {
      this.edit = !this.edit
    },
    setLocale: async function() {
      const { token, account } = await beanieSDK.setSessionLocale({ locale: this.locale })
      this.$store.dispatch('SET_SESSION_LOCALE', { token, account, locale: this.locale })
      this.toggleEdit()
    }
  }
}
</script>
