<template>
  <h1>Account language</h1>
  <div v-if="edit">
    <select @change="setLocale" id="locale" v-model="locale">
      <option :value="locale">Select language</option>
      <option v-for="[localeValue, label] in Object.entries(localeOptions)" v-bind:key="localeValue" :value="localeValue">{{ label }}</option>
    </select>
  </div>
  <div v-else>
    <span title="Select your account language" @click="toggleEdit" style="cursor: pointer">{{ localeOptions[this.account.locale] || 'None' }}</span>
  </div>
</template>

<script>

import { LOCALE_OPTIONS } from "@/constants"

export default {
  name: 'AccountLocale',
  props: ['account'],
  data: function() {
    return {
      edit: false,
      locale: '',
      localeOptions: LOCALE_OPTIONS
    }
  },
  methods: {
    toggleEdit: function() {
      this.edit = !this.edit
    },
    setLocale: async function() {
      this.$emit('set-account-locale', this.locale)
      this.toggleEdit()
    }
  }
}
</script>
