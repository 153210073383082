import { createRouter, createWebHistory } from 'vue-router'
import HomePage from "./views/HomePage.vue"
import Login from "./views/Login.vue"
import Register from "./views/Register.vue"
import Profile from "./views/Profile.vue"
import Savegames from "./views/Savegames.vue"
import ActivationCode from "./views/ActivationCode.vue"
import Merge from "./views/Merge.vue"
import VerifyEmail from "@/views/VerifyEmail"
import SendVerificationEmail from "./views/SendVerificationEmail"
import AddIdentity from "@/views/AddIdentity"
import SendResetPasswordEmail from "@/views/SendResetPasswordEmail"
import ResetPassword from "@/views/ResetPassword"
import ChangePassword from "@/views/ChangePassword"
import store from "./vuex/store"
import tryForumSso from './utils/forumSso'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomePage
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/register',
        name: 'register',
        component: Register
    },
    {
        path: '/activate',
        name: 'activate',
        component: ActivationCode
    },
    {
        path: '/verifyEmail',
        name: 'verify-email',
        component: VerifyEmail
    },
    {
        path: '/merge',
        name: 'merge',
        component: Merge
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/changePassword',
        name: 'change-password',
        component: ChangePassword,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/addIdentity',
        name: 'add-identity',
        component: AddIdentity,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/savegames',
        name: 'savegames',
        component: Savegames,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/sendVerificationEmail',
        name: 'send-verification-email',
        component: SendVerificationEmail,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/sendResetPasswordEmail',
        name: 'send-reset-password-email',
        component: SendResetPasswordEmail
    },
    {
        path: '/resetPassword',
        name: 'reset-password',
        component: ResetPassword
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

const hasQueryParams = route => !!Object.keys(route.query).length
const hasSsoParams = route => !!route.query.sso

router.beforeResolve(async to => {
    if (hasSsoParams(to)) {
        console.log('matanga!', to)
        if (await tryForumSso(to)) return false
    }
})

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next()
        }
        next('/login')
    } else {
        if(!hasQueryParams(to) && hasQueryParams(from) && hasSsoParams(from)) {
            const toWithQuery = Object.assign({}, to, {query: from.query})
            next(toWithQuery)
        } else {
            next()
        }
    }
})

export default router