<template>

  <img alt="spinner" src="@/assets/loading-wheel.gif">

</template>

<script>
export default {
  name: "Spinner"
}
</script>

<style scoped>
  img {
    height: 100%;
    width: 100%;
  }
</style>