<template>
  <div class="container">

    <div class="linkAccountPanel">
      <div v-if="done">
        <h1>We have sent a verification email to {{ this.email }} !</h1>
      </div>
      <form v-else class="login" @submit.prevent="sendVerificationEmail">
        <h1>Verify your email</h1>
        <div class="codePanel">
          <label>Enter the email address you want to send a verification email</label>
          <input required v-model="email" type="email" placeholder="jane@hotmail.com"/>
          <button class="bigButton" type="submit">enter</button>
        </div>

        <div class="error">
          <p>
            {{ error }}
          </p>
        </div>
      </form>
    </div>

  </div>
</template>

<script>
import { beanieSDK } from '@/vuex/store'

export default {
  data(){
    return {
      email : this.$route.query.email || '',
      error: '',
      done: false
    }
  },

  created() {
    if (this.email) {
      this.sendVerificationEmail()
    }
  },

  methods: {
    async sendVerificationEmail () {
      try {
        this.error = ''

        await beanieSDK.resendVerificationEmail({ email: this.email })

        this.done = true
      } catch (e) {
        this.error = e.message
      }
    },
  }
}
</script>