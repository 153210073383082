import store, { beanieSDK as client } from "../vuex/store"

const getDefaultAvatar = async () => {
  return 'https://cdn.onlinewebfonts.com/svg/img_133483.png'
}

const getAvatarUrl = async () => {
  const account = store.getters.account
  if (!account) return getDefaultAvatar()

  try {
    const signedUrl = await client.getDownloadSignedUrl({
      entityType: 'Account',
      entityId: account._id,
      visibility: 'Public',
      filename: 'avatar'
    })
    return signedUrl
  } catch (e) {
    console.log('ERROR getting avatar', e.message)

    return getDefaultAvatar()
  }
}

export default getAvatarUrl