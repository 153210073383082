<template>
  <div class="container">

    <div class="linkAccountPanel">
      <div v-if="done">
        <h1>We have sent an email to {{ this.email }} !</h1>
        <p>It has a link to reset your password.</p>
      </div>
      <form v-else class="login" @submit.prevent="sendResetPasswordEmail">
        <h1>Send reset password email</h1>
        <div class="codePanel">
          <label>Enter your email address</label>
          <input required v-model="email" type="email" placeholder="jane@hotmail.com"/>
          <button class="bigButton" type="submit">enter</button>
        </div>

        <div class="error">
          <p>
            {{ error }}
          </p>
        </div>
      </form>
    </div>

  </div>
</template>

<script>
import { beanieSDK } from '@/vuex/store'

export default {
  data(){
    return {
      email : '',
      error: '',
      done: false
    }
  },

  methods: {
    async sendResetPasswordEmail () {
      try {
        this.error = ''

        await beanieSDK.sendResetPasswordEmail({ email: this.email })

        this.done = true
      } catch (e) {
        this.error = e.message
      }
    },
  }
}
</script>