<template>
  <div class="container">

    <div class="linkAccountPanel" v-if="!activationCodeToken">
      <form  class="login" @submit.prevent="loginWithActivationCode">
        <h1>Link your console account</h1>
        <div class="codePanel">
          <label>Enter your activation code from the console</label>
          <input required v-model="code" type="text" placeholder="code"/>
          <button class="bigButton" type="submit">enter</button>
        </div>

        <div class="error">
          <p>
            {{ error }}
          </p>
        </div>
      </form>
    </div>

  </div>
</template>

<script>
import { beanieSDK } from '@/vuex/store'

export default {
  data(){
    return {
      code : this.$route.query.code || '',
      activationCodeToken: '',
      error: ''
    }
  },

  created() {
    if (this.code) {
      this.loginWithActivationCode()
    }
  },

  methods: {
    async loginWithActivationCode () {
      try {
        this.error = ''

        const { token, originIdentity } = await beanieSDK.signInWithSessionCode({ code: this.code, sessionLocale: this.$store.getters.sessionLocale })
        await this.$store.dispatch('SET_SESSION_CODE_LOGIN_RESPONSE', { token, originIdentity })

        await this.$router.push('/merge')
      } catch (e) {
        this.error = e.message
      }
    },
  }
}
</script>