<template>
  <div class="identityContainer">
    <h4 style="flex-basis: 100%" v-if="identity._id === sessionIdentityId">This is your current identity</h4>
    <component v-bind:is="identityComponent" :identity="this.identity"></component>
  </div>
</template>

<script>
import EmailIdentity from "./EmailIdentity.vue"
import GoogleIdentity from "./GoogleIdentity.vue"
import CustomIdIdentity from "./CustomIdIdentity.vue"

const kindToComponent = {
  Email: EmailIdentity,
  CustomId: CustomIdIdentity,
  GoogleId: GoogleIdentity
}

export default {
  name: "Identity",
  props: ['identity', 'sessionIdentityId'],
  data () {
    return {
      identityComponent: kindToComponent[this.identity.kind]
    }
  }
}
</script>