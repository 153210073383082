<template>
  <GameTitle v-show="!this.contained" />
  <div class="login">
    <LoginWithEmail :contained="this.contained" @logged-in="handleLoggedIn"/>
    <Or />
    <LoginWithGoogle :contained="this.contained" @logged-in="handleLoggedIn"/>
  </div>
</template>

<script>
import GameTitle from '@/components/GameTitle'
import Or from '@/components/Or'
import tryForumSso from '@/utils/forumSso'
import LoginWithEmail from "../components/LoginMethods/LoginWithEmail.vue"
import LoginWithGoogle from "../components/LoginMethods/LoginWithGoogle.vue"

export default {
  props: ['contained'],

  components: {
    Or,
    GameTitle,
    LoginWithEmail,
    LoginWithGoogle
  },

  methods: {
    async handleLoggedIn () {
      await tryForumSso(this.$route)
      this.$emit('logged-in')
    }
  }

}
</script>