<template>
  <GameTitle v-show="!this.contained"/>
  <div class="register">
    <RegisterWithEmail :contained="contained" @signed-up="handleSignedUp"/>
    <Or />
    <RegisterWithGoogle :contained="contained" @signed-up="handleSignedUp"/>
  </div>
</template>

<script>
import GameTitle from '@/components/GameTitle'
import Or from '@/components/Or'
import RegisterWithEmail from '../components/RegisterMethods/RegisterWithEmail.vue'
import RegisterWithGoogle from '../components/RegisterMethods/RegisterWithGoogle.vue'
import tryForumSso from '@/utils/forumSso'

export default {
  props: ['contained'],

  components: {
    Or,
    GameTitle,
    RegisterWithEmail,
    RegisterWithGoogle
  },
  methods: {
    async handleSignedUp () {
      await tryForumSso(this.$route)
      this.$emit('signed-up')
    }
  }
}
</script>