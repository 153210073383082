<template>
  <div id="nav">
    <router-link to="/">Home</router-link>
    <router-link v-if="isLoggedIn" to="/profile">Profile</router-link>
    <router-link v-if="isLoggedIn" to="/savegames">Savegames</router-link>
    <router-link to="/activate">Use activation code</router-link>
    <div v-if="isLoggedIn">
      <Locale />
    </div>
    <div id="account-menu" v-if="isLoggedIn">
      <Avatar />
      <button @click="logout">Logout</button>
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/Avatar"
import Locale from "@/components/Locale"

export default {
  name: 'Navbar',
  components: { Locale, Avatar },
  computed : {
    isLoggedIn : function() {
      return this.$store.getters.isLoggedIn
    }
  },
  methods: {
    logout: function () {
      this.$store.dispatch('LOGOUT')
          .then(() => {
            this.$router.push('/login')
          })
    }
  }
}
</script>
