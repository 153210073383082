<template>
  <div v-if="done" class="register">
    <h2>Identity successfully added !</h2>
  </div>
  <div v-else class="register">
    <h2>Add a new identity to your account</h2>
    <AddEmailPassword @added-identity="handleAddedIdentity"/>
    <Or />
    <AddGoogle @added-identity="handleAddedIdentity"/>
  </div>
</template>

<script>
import AddEmailPassword from "../components/AddMethods/AddEmailPassword.vue"
import AddGoogle from "../components/AddMethods/AddGoogle.vue"
import Or from "@/components/Or"

export default {
  props: ['token'],

  data() {
    return {
      done: false
    }
  },

  components: {
    Or,
    AddGoogle,
    AddEmailPassword
  },

  methods: {
    handleAddedIdentity () {
      this.done = true

      /*
        If there are errors when adding an identity, these
        will be displayed by the child components
       */
    }
  }
}
</script>