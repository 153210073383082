<template>
  <div class="homepage">

    <GameTitle />

    <div v-if="!isLoggedIn">
      <div class="loginOrRegisterPanel">
        <div>
          <router-link to="/login">Login</router-link>
        </div>
        <Or />
        <div>
          <router-link to="/register">Register</router-link>
        </div>
      </div>
    </div>

    <div v-else>
      This is Home Page main content

      <div class="container">
        <WelcomeMessage />
      </div>

    </div>

  </div>
</template>

<script>
import GameTitle from '@/components/GameTitle'
import Or from '@/components/Or'
import WelcomeMessage from "@/components/WelcomeMessage"

export default {
  name: "HomePage",
  components: { WelcomeMessage, Or, GameTitle },
  computed : {
    isLoggedIn : function() {
      return this.$store.getters.isLoggedIn
    }
  }
}
</script>

<style scoped>
  img {
    width: 20vw;
  }
  ul {
    list-style-type: none;
  }
</style>
