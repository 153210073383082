<template>
  <div class="registerWithEmail">
    <h4>Register with email</h4>
    <form @submit.prevent="register">
      <label for="email">Email</label>
      <div>
        <input id="email" type="email" v-model="email" required placeholder="jane@demo.com">
      </div>

      <label for="password">Password</label>
      <div>
        <input id="password" type="password" v-model="password" required placeholder="****">
      </div>

      <div>
        <button class="bigButton" type="submit">Register</button>
      </div>

      <div class="error">
        <p>
          {{ error }}
          {{ this.$attrs.error }}
        </p>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data(){
    return {
      email : "",
      password : "",
      error: ""
    }
  },

  methods: {
    register() {
      const data = {
        email: this.email,
        password: this.password
      }
      this.$emit('register-with-email-form', data)
    }
  }
}
</script>