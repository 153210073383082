<template>
  <RegisterWithEmailForm :error="error" @register-with-email-form="register"/>
</template>

<script>
import RegisterWithEmailForm from "./RegisterWithEmailForm.vue"
import { beanieSDK } from "../../vuex/store"

export default {
  components: {
    RegisterWithEmailForm
  },

  props: ['contained'],

  data() {
    return {
      error: ''
    }
  },

  methods: {
    async register(data) {
      try {
        const { token, account } = await beanieSDK.signUpWithEmail({ ...data, sessionLocale: this.$store.getters.sessionLocale })
        this.$store.dispatch('SET_AUTHENTICATION_DATA', { token, account })

        if (this.contained) {
          this.$emit('signed-up')
        } else {
          await this.$router.push('/')
        }
      } catch (e) {
        this.error = e.message
      }
    }
  }
}
</script>