<template>
  <div>
    <hr>
    <div v-if="error">
      <p>Error fetching welcome message!</p>
      <p class="error">{{ error }}</p>
    </div>
    <h4 v-else>{{ message }}</h4>
    <hr>
  </div>
</template>

<script>

export default {
  computed: {
    message() {
      return this.$store.getters.welcomeMessage.message
    },
    error() {
      return this.$store.getters.welcomeMessage.error
    },
  },
}
</script>