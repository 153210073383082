<template>
  <div class="container">

    <div class="linkAccountPanel">
      <div v-if="done">
        <h1>Your email has been verified !</h1>
      </div>
      <form v-else class="login" @submit.prevent="verifyEmail">
        <h1>Verify your email</h1>
        <div class="codePanel">
          <label>Enter the activation token you received</label>
          <input required v-model="token" type="text" placeholder="token"/>
          <button class="bigButton" type="submit">enter</button>
        </div>

        <div class="error">
          <p>
            {{ error }}
          </p>
        </div>
      </form>
    </div>

  </div>
</template>

<script>
import { beanieSDK } from '@/vuex/store'

export default {
  data(){
    return {
      token : this.$route.query.verificationT || '',
      error: '',
      done: false
    }
  },

  created() {
    if (this.token) {
      this.verifyEmail()
    }
  },

  methods: {
    async verifyEmail () {
      try {
        this.error = ''

        await beanieSDK.verifyEmail({ token: this.token })

        this.done = true
      } catch (e) {
        this.error = e.message
      }
    },
  }
}
</script>