<template>
  <div>

    <Avatar :big="true" ref="avatar"/>

    <AvatarUploader @uploaded="reloadAvatar" />

  </div>
</template>

<script>
import Avatar from "@/components/Avatar"
import AvatarUploader from "@/components/AvatarUploader"

export default {
  name: "AvatarEditor",
  components: {
    AvatarUploader, Avatar
  },

  methods: {
    reloadAvatar() {
      const avatar = this.$refs.avatar
      avatar.load()
    }
  }
}
</script>
