<template>
  <div class="registerWithGoogle">
    <form class="login" @submit.prevent="login">
      <div>
        <span v-if="loadingGoogleButton">(Loading...)</span>
        <div id="google-sign-up"></div>
      </div>
      <div class="error">
        <p>
          {{ error }}
          {{ this.$attrs.error }}
        </p>
      </div>
    </form>
  </div>
</template>

<script>

import {loadGoogleScript} from "../../lib/loadGoogleScript";

const onSuccess = function () {
  const authResponse = this.googleApi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true);

  const accessToken = authResponse.access_token;

  this.registerWithGoogle(accessToken)
};

const onFailure = (e) => {
  this.error = JSON.stringify(e)
}

const renderSigninButton = function () {
  this.googleApi.signin2.render('google-sign-up', {
    'scope': 'profile email',
    'width': 240,
    'height': 50,
    'longtitle': true,
    'theme': 'dark',
    'onsuccess': this.onSuccess,
    'onfailure': onFailure
  })
  this.loadingGoogleButton = false
}

export default {

  async created () {
    this.googleApi = await loadGoogleScript()
    this.renderSigninButton()
  },

  data: function () {
    return {
      loadingGoogleButton: true,
      googleApi: {},
      error: ""
    }
  },

  methods: {
    renderSigninButton,
    onSuccess,
    registerWithGoogle(accessToken) {
      this.cleanUpGoogle()
      this.$emit('register-with-google-form', accessToken)
    },
    cleanUpGoogle() {
      this.googleApi.auth2.getAuthInstance().signOut()
      this.googleApi.auth2.getAuthInstance().disconnect()
    }
  }

}
</script>