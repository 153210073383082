<template>
  <div class="flexContainer">
    <AvatarSettings />
    <div>
      <div>
        <h1>Personal data
          <span>
            <button @click="toggleEdit">
              <img alt="edit" title="Edit" style="width: 1rem; cursor: pointer" src="@/assets/edit.png">
            </button>
          </span>
        </h1>
        <form v-if="edit" @submit.prevent="handleSubmit">
          <label>Name:</label>
          <input type="text" v-model="name" />
          <br>
          <label>Surname:</label>
          <input type="text" v-model="surname" />
          <br>
          <button type="submit">Submit</button>
        </form>
        <div v-else>
          <p>Name: {{ this.account.name }}</p>
          <p>Surname: {{ this.account.surname }}</p>
        </div>
      </div>
      <AccountLocale :account="this.account" @set-account-locale="bubbleSetLocale"/>
      <div style="flex-basis: 50%">
        <p>
          Account was created at
        </p>
        <p>
          {{ account.createdAt }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import AvatarSettings from "@/components/AvatarSettings"
import AccountLocale from "@/components/AccountLocale";

export default {
  props: ['account'],
  components: {
    AccountLocale,
    AvatarSettings
  },
  data() {
    return {
      edit: false,
      name: '',
      surname: ''
    }
  },
  methods: {
    toggleEdit() {
      this.edit = !this.edit
    },
    async handleSubmit() {
      this.$emit('set-name', this.name, this.surname)
      this.toggleEdit()
    },
    bubbleSetLocale(locale) {
      this.$emit('set-account-locale', locale)
    }
  },
}
</script>