<template>
  <div v-if="!confirmedOriginIdentity">
    <h2>Continue to link your Account with "{{ originIdentity.displayName }} [{{ originIdentity.platform }}]"</h2>
    <button @click="confirmOriginIdentity" class='activeButton'>Confirm</button>
  </div>
  <div v-else>
    <div class="authSelector" v-show="!this.isLoggedIn && !this.done && !this.error">
      <button @click="showLoginForm" :class="this.loginForm ? 'activeButton' : ''">Login</button>
      <button @click="showRegisterForm" :class="this.registerForm ? 'activeButton' : ''">Register</button>
    </div>
    <div class="container" v-if="this.isLoggedIn">
      <h2 v-show="this.done">Your account was successfully linked with "{{ originIdentity.displayName }} [{{ originIdentity.platform }}]"!</h2>
      <h2 v-show="this.merging && !this.error">Merging accounts...</h2>
      <div class="error">
        <p>
          {{ error }}
        </p>
      </div>
    </div>
    <div v-else>
      <div class="container" v-if="loginForm">
        <h2>Already registered?</h2>
        <Login :contained="true" @logged-in="merge"/>
      </div>

      <div class="container" v-if="registerForm">
        <h2>Or... Sign up now</h2>
        <Register :contained="true" @signed-up="merge"/>
      </div>
    </div>
  </div>
</template>

<script>
import Login from "./Login.vue"
import { beanieSDK } from "@/vuex/store"
import Register from "@/views/Register"
export default {
  components: {
    Register,
    Login,
  },

  data(){
    return {
      confirmedOriginIdentity: false,
      activationCodeToken: this.$store.getters.activationCodeToken,
      originIdentity: this.$store.getters.originIdentity,
      error: '',
      merging: false,
      done: false,
      loginForm: true,
      registerForm: false,
    }
  },

  computed : {
    isLoggedIn : function() {
      return this.$store.getters.isLoggedIn
    }
  },

  methods: {
    confirmOriginIdentity () {
      this.confirmedOriginIdentity = true
      if (this.isLoggedIn) {
        this.merge()
      }
    },
    async merge () {
      try {
        this.merging = true
        await beanieSDK.merge({ accountToken: this.activationCodeToken })
        this.merging = false
        await this.$store.dispatch('CLEAR_SESSION_CODE_LOGIN_RESPONSE')
        this.done = true
      } catch (e) {
        if (e.message === 'Cannot merge to same account') {
          this.error = 'Your console is already linked'
        } else {
          this.error = e.message
        }
      }
    },
    showLoginForm() {
      this.loginForm = true
      this.registerForm = false
    },
    showRegisterForm() {
      this.registerForm = true
      this.loginForm = false
    },
  }
}
</script>