<template>
  <RegisterWithGoogleForm :error="this.error" @register-with-google-form="handleForm"/>
</template>

<script>
import RegisterWithGoogleForm from "../RegisterMethods/RegisterWithGoogleForm.vue"
import {beanieSDK} from "../../vuex/store";

export default {
  components: {
    RegisterWithGoogleForm
  },

  data(){
    return {
      error: ""
    }
  },

  methods: {
    async handleForm(accessToken) {
      try {
        const { account } = await beanieSDK.addGoogle({ accessToken })
        this.$store.dispatch('SET_ACCOUNT', { account })

        this.$emit('added-identity')
      } catch (e) {
        this.error = e.message
      }
    }
  }
}
</script>