const CLIENT_ID = '652499498144-atnlqd0kn57ej7ua8b6q6vfu3kmnpvqo.apps.googleusercontent.com'

export const loadGoogleScript = () => {
    return new Promise(resolve => {
        function onGoogleScriptLoad () {
            const googleApi = window.gapi

            googleApi.load('auth2', () => {
                (async () => {
                    await googleApi.auth2.init({
                        client_id: CLIENT_ID
                    })
                    resolve(googleApi)
                })()
            })
        }

        const id = 'google-js'
        const src = 'https://apis.google.com/js/platform.js'

        const firstJs = document.getElementsByTagName('script')[0]

        // Prevent script from loading twice
        if (document.getElementById(id)) {
            if(window.gapi) {
                resolve(window.gapi)
            }
            return
        }
        const js = document.createElement('script')
        js.id = id
        js.src = src
        js.onload = onGoogleScriptLoad
        firstJs.parentNode.insertBefore(js, firstJs)
    })
}