<template>
  <div class='savegameCard'>
    <div>
      <h4>Slot: {{ savegame.slot }}</h4>
      <p>Updated at: {{ savegame.lastModified }}</p>
    </div>
    <div>
      <span>Used for stats</span>
      <input @click='setStatsPreference(savegame)' type='checkbox' v-bind:checked='savegame.isMain'/>
    </div>
  </div>
</template>

<script>
import { beanieSDK } from '@/vuex/store'

export default {
  name: 'SavegameCard',
  methods: {
    setStatsPreference: async function ({ slot, isMain }) {
      await beanieSDK.statsPreferences({
        [!isMain ? 'main' : 'secondary']: [slot]
      })

      this.$emit('changed-stats-preferences')
    },
  },
  props: ['savegame']
}
</script>