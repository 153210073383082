// FORUM SSO stuff
import Discourse from '../utils/discourse-sso'
import store from '../vuex/store'
import { beanieSDK } from '../vuex/store'

const sso = new Discourse(process.env.VUE_APP_FORUM_SSO_SECRET)

const collectEmail = accountObj => {
  const emailIdentity = (accountObj.identities || []).find(i => !!i.email)
  return emailIdentity.email
}
const makeName = ({ name = '', surname = '' }) => `${name}${surname ? ` ${surname}` : ''}`

/**
 * @return true if sso, false if not
 */
const tryForumSso = async route => {
  const payload = route.query.sso
  const sig = route.query.sig

  // RECEIVED SSO REQUEST FROM FORUM, if not... do nothing
  if (!(payload || sig || store.getters.isLoggedIn)) return false;

  if (sso.validate(payload, sig)) {
    const nonce = sso.getNonce(payload)
    const url = sso.getReturnUrl(payload)

    // ---> BUILD FORUM AUTH USER
    const { account } = await beanieSDK.me()
    const email = collectEmail(account)

    const q = sso.buildLoginString({
      nonce,
      external_id: account._id,
      email,
      username: email.split('@')[0],
      ...(account.name || account.surname) && { name: makeName(account) }
    })
    // ---> NAVIGATE TO FORUM
    window.location.href = `${url}?${q}`
    return true
  }

  return false
}

export default tryForumSso