<template>
  <div class="container">

    <div class="linkAccountPanel">
      <div v-if="done">
        <h1>Your password has been reset !</h1>
      </div>
      <form v-else class="login" @submit.prevent="resetPassword">
        <h1>Reset your password</h1>
        <div class="codePanel">
          <label>New password</label>
          <input required v-model="newPassword" @change="validatePasswords" type="password"/>

          <label>Confirm your new password</label>
          <input required v-model="newPasswordConfirm" @change="validatePasswords" type="password"/>

          <button class="bigButton" :disabled="this.passwordsDontMatch" type="submit">enter</button>
        </div>

        <div class="error">
          <p>
            {{ error }}
          </p>
        </div>
      </form>
    </div>

  </div>
</template>

<script>
import { beanieSDK } from '@/vuex/store'

export default {
  data(){
    return {
      token : this.$route.query.resetT || '',
      newPassword: '',
      newPasswordConfirm: '',
      passwordsDontMatch: false,
      error: '',
      done: false
    }
  },

  methods: {
    async resetPassword () {
      try {
        this.error = ''

        await beanieSDK.resetPassword({ token: this.token, newPassword: this.newPassword })

        this.done = true
      } catch (e) {
        this.error = e.message
      }
    },
    validatePasswords () {
      if (this.newPassword !== this.newPasswordConfirm) {
        this.error = 'Passwords don\'t match'
        this.passwordsDontMatch = true
      } else {
        this.error = ''
        this.passwordsDontMatch = false
      }
    }
  },

}
</script>