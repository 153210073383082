<template>
  <div>

    <Spinner v-if="downloading" />
    <img v-else
         alt="avatar"
         :src="avatarUrl"
         v-bind:style= "[big ? { width: '20vw' } : { width: '2rem' }]"
    />

  </div>
</template>

<script>
import getAvatarUrl from "@/utils/getAvatarUrl"
import Spinner from "@/components/Spinner"

export default {
  name: "Avatar",
  props: ['big'],
  components: {
    Spinner
  },
  data() {
    return {
      downloading: true,
      avatarUrl: '',
      error: ''
    }
  },

  created() {
    this.load()
  },

  methods: {
    async load() {
      try {
        this.downloading = true
        this.avatarUrl = await getAvatarUrl()
        this.downloading = false
      } catch (e) {
        this.error = e.message
      }
    }
  }

}
</script>

<style scoped>

  img {
    width: 2rem;
  }

</style>