import Vuex from 'vuex'
import { BeanieTitlesSDK as titles } from 'beanie-titles-sdk'
import { TITLE_ID } from "@/constants"
import { getWelcomeMessage } from "@/utils/welcomeMessage"

const authToken = localStorage.getItem('token')
export const beanieSDK = titles.init({
    titleId: TITLE_ID,
    token: authToken,
    baseURL: process.env.VUE_APP_API_BASE_URL || 'http://localhost:8001/'
})


const store = new Vuex.Store({
    state: {
        token: authToken || '',
        activationCodeToken: '',
        account : JSON.parse(localStorage.getItem('account') || '{}'),
        sessionLocale: sessionStorage.getItem('locale') || navigator.language,
        welcomeMessage: { message: '', error: ''}
    },
    mutations: {
        set_auth_data(state, { token, account }){
            state.token = token
            state.account = account
        },
        set_session_code_login_response(state, { token, originIdentity }){
            state.activationCodeToken = token
            state.originIdentity = originIdentity
        },
        clear_session_code_login_response(state){
            state.activationCodeToken = undefined
            state.originIdentity = undefined
        },
        set_session_locale(state, { locale }){
            state.sessionLocale = locale
        },
        set_welcome_message(state, { message, error }){
            state.welcomeMessage = { message, error }
        },
        logout(state){
            state.token = ''
        },
    },
    actions: {
        LOGOUT({commit}){
            return new Promise((resolve) => {
                commit('logout')
                localStorage.removeItem('token')
                resolve()
            })
        },
        async SET_SESSION_CODE_LOGIN_RESPONSE({commit}, { token, originIdentity }){
            commit('set_session_code_login_response', { token, originIdentity })
        },
        CLEAR_SESSION_CODE_LOGIN_RESPONSE({ commit }) {
            commit('clear_session_code_login_response')
        },
        async SET_AUTHENTICATION_DATA ({ commit, dispatch }, { token, account }){
            localStorage.setItem('token', token)
            localStorage.setItem('account', JSON.stringify(account))
            commit('set_auth_data', { account, token })

            dispatch('FETCH_WELCOME_MESSAGE')
        },
        async SET_ACCOUNT ({commit}, { account }){
            localStorage.setItem('account', JSON.stringify(account))
            commit('set_account', { account })
        },
        async SET_SESSION_LOCALE ({ commit, dispatch }, { token, account, locale }){
            dispatch('SET_AUTHENTICATION_DATA', { token, account })

            sessionStorage.setItem('locale', locale)
            commit('set_session_locale', { locale })
        },
        async FETCH_WELCOME_MESSAGE ({ commit }){
            try {
                const message = await getWelcomeMessage(beanieSDK)
                commit('set_welcome_message', { message, error: '' })
            } catch (e) {
                commit('set_welcome_message', { error: e.message })
            }
        },
    },
    getters: {
        isLoggedIn: state => !!state.token,
        token: state => state.token,
        account: state => state.account,
        activationCodeToken: state => state.activationCodeToken,
        originIdentity: state => state.originIdentity,
        sessionLocale: state => state.sessionLocale,
        welcomeMessage: state => state.welcomeMessage,
    }
})

const onStoreReady = store => {
    if(authToken) store.dispatch('FETCH_WELCOME_MESSAGE')
}
onStoreReady(store)

export default store