<template>
  <div>

    <Spinner v-if="uploading" />

    <div v-else class="container container--vertical">
      <label for="file-input">Change avatar</label>
      <input id="file-input" type="file" v-on:change="onFileChange">
      <button v-show="file" v-on:click="uploadAvatar">Upload</button>

      <div class="error">
        <p>
          {{ error }}
        </p>
      </div>
    </div>

  </div>
</template>

<script>
import { beanieSDK } from "@/vuex/store"
import getAvatarUrl from "@/utils/getAvatarUrl"
import Spinner from "@/components/Spinner"
import { fileToBlob } from "@/utils/files"

export default {
  name: "AvatarUploader",
  components: {Spinner},
  data() {
    return {
      uploading: false,
      error: '',
      file: null
    }
  },

  async created() {
    try {
      this.avatarUrl = await getAvatarUrl()
      this.downloading = false
    } catch (e) {
      this.error = e.message
    }
  },

  methods: {
    onFileChange(e) {
      const files = e.target.files
      if (!files.length)
        return

      this.file = files[0]
    },
    async uploadAvatar() {
      try {
        this.uploading = true

        const blob = await fileToBlob(this.file)
        await beanieSDK.uploadFile({
          blob,
          filename: 'avatar', // remote file name
          contentType: blob.type,
          entityType: 'Account',
          entityId: this.$store.getters.account._id,
          visibility: 'Public'
        })
        this.$emit('uploaded')
        this.file = null
      } catch (e) {
        this.error = e.message
      } finally {
        this.uploading = false
      }
    }
  }
}
</script>

<style scoped>

  img {
    width: 20vw;
  }

</style>