<template>
  <div>
    <div class="container">
      <h1>My savegames</h1>

      <div class="error">
        <p>
          {{ error }}
        </p>
      </div>

      <div v-if="savegames.length === 0">
        <p>You don't have savegames yet</p>
      </div>

      <div v-else class="savegameCardList">
        <SavegameCard v-for="(savegame, index) in savegames" :key="index" @changed-stats-preferences="handleChangedStatsPreferences" :savegame="savegame"/>
      </div>
    </div>

  </div>
</template>

<script>
import SavegameCard from "../components/SavegameCard.vue"
import { beanieSDK } from '@/vuex/store'

export default {
  components: {
    SavegameCard
  },
  data() {
    return {
      savegames: [],
      error: ''
    }
  },
  created() { this.loadSavegames() },
  methods: {
    handleChangedStatsPreferences () {
      this.loadSavegames()
    },
    async loadSavegames() {
      try {
        const { savegames } = await beanieSDK.getSavegamesList()
        this.savegames = savegames.sort((a, b) => {
          return b.lastModified < a.lastModified ? -1 : 0
        })
      } catch (e) {
        this.error = e.message
      }
    }
  }
}
</script>