<template>
  <div>
    <img alt="googleIdentity" style="width: 1rem" src="@/assets/gmail.png">
    {{ identity.data.email }}
  </div>
  <div>
    <p>
      Created at: {{ identity.createdAt }}
    </p>
    <p>
      Last login at: {{ identity.lastLoginAt }}
    </p>
  </div>
</template>

<script>
export default {
  name: "Identity",
  props: ['identity']
}
</script>

<style scoped>

</style>