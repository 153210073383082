<template>
  <div class="loginWithGoogle">
    <form class="login" @submit.prevent="login">
      <div>
        <span v-if="loadingGoogleButton">(Loading...)</span>
        <div id="google-sign-in"></div>
      </div>
      <div class="error">
        <p>
          {{ error }}
        </p>
      </div>
    </form>
  </div>
</template>

<script>

import {loadGoogleScript} from "../../lib/loadGoogleScript"
import { beanieSDK } from "../../vuex/store"

const onSuccess = function () {
  const authResponse = this.googleApi.auth2.getAuthInstance().currentUser.get().getAuthResponse(true);

  const accessToken = authResponse.access_token;

  this.login(accessToken)
};

const onFailure = (e) => {
  this.error = JSON.stringify(e)
}

const renderSigninButton = function () {
  this.googleApi.signin2.render('google-sign-in', {
    'scope': 'profile email',
    'width': 240,
    'height': 50,
    'longtitle': true,
    'theme': 'dark',
    'onsuccess': this.onSuccess,
    'onfailure': onFailure
  })
  this.loadingGoogleButton = false
}

export default {

  async created () {
    this.googleApi = await loadGoogleScript()
    this.renderSigninButton()
  },

  data: function () {
    return {
      loadingGoogleButton: true,
      googleApi: {},
      error: ""
    }
  },

  props: ['contained'],

  methods: {
    renderSigninButton,
    onSuccess,
    async login(accessToken) {
      try {
        const { token, account } = await beanieSDK.signInWithGoogle({ accessToken, sessionLocale: this.$store.getters.sessionLocale })
        this.$store.dispatch('SET_AUTHENTICATION_DATA', { token, account })

        if (this.contained) {
          //  Avoid changing route, and let parent component handle the situation
          this.$emit('logged-in')
        } else {
          await this.$router.push('/')
        }
      } catch (e) {
        this.error = e.message
      } finally {
        this.cleanUpGoogle()
      }
    },
    cleanUpGoogle() {
      this.googleApi.auth2.getAuthInstance().signOut()
      this.googleApi.auth2.getAuthInstance().disconnect()
    }
  }

}
</script>