<template>
  <div class="container">
    <h1>My profile</h1>

    <div style="width: 80%; margin: auto">
      <AccountData @set-name="this.setName" :account="this.account" @set-account-locale="setLocale" />
    </div>

    <div v-show="error" class="error">
      <p>Error loading profile:</p>
      <p>
        {{ error }}
      </p>
    </div>

    <div style="width: 80%; margin: auto">
      <h2>Identities ({{ account.identities.length }})</h2>
      <div v-for="(identity, index) in account.identities" :key="index">
        <Identity :identity="identity" :sessionIdentityId="identityId"/>
      </div>
    </div>

    <br />

    <div class="container">
      <router-link to="/addIdentity">
        <button class="bigButton" type="submit">Add a new identity</button>
      </router-link>
    </div>

  </div>
</template>

<script>
import { beanieSDK } from "@/vuex/store"
import Identity from '../components/Identity/IdentityCard.vue'
import AccountData from "@/views/AccountData";

export default {
  components: {
    AccountData,
    Identity,
  },
  data() {
    return {
      account: {
        identities: [],
        name: ''
      },
      identityId: '',
      error: '',
    }
  },
  async created() {
    try {
      const { account } = await beanieSDK.me()
      this.account = account
      this.identityId = beanieSDK.identityId
    } catch (e) {
      this.error = e.message
    }
  },
  methods: {
    async setName (name, surname) {
      try {
        await beanieSDK.setName({ name, surname })
        this.account.name = name
        this.account.surname = surname
        this.error = ''
      } catch (e) {
        this.error = `Error setting name: ${e.message}`
      }
    },
    async setLocale (locale) {
      try {
        await beanieSDK.setAccountLocale({ locale })
        this.account.locale = locale
        this.error = ''
      } catch (e) {
        this.error = `Error setting locale: ${e.message}`
      }
    },
  }
}
</script>