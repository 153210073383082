<template>
  <div class="loginWithEmail">
    <form class="login" @submit.prevent="login">
      <h1>Login with email</h1>

      <label>Email</label>
      <div>
        <input required v-model="email" type="email" placeholder="jane@demo.com"/>
      </div>

      <label>Password</label>
      <div>
        <input required v-model="password" type="password" placeholder="****"/>
      </div>

      <button class="bigButton" type="submit">Login</button>

      <div>
        <router-link to="/sendResetPasswordEmail">
          <i>Forgot your password?</i>
        </router-link>
      </div>

      <div class="error">
        <p>
          {{ error }}
        </p>
      </div>
    </form>
  </div>
</template>

<script>
import { beanieSDK } from "@/vuex/store"
import tryForumSso from '@/utils/forumSso'

export default {
  data(){
    return {
      email : "",
      password : "",
      error: ""
    }
  },

  props: ['contained'],

  methods: {
    async login() {
      try {
        const { token, account } = await beanieSDK.signInWithEmail({
          email: this.email, password: this.password, sessionLocale: this.$store.getters.sessionLocale }
        )

        this.$store.dispatch('SET_AUTHENTICATION_DATA', { token, account })

        if (this.contained) {
          //  Avoid changing route, and let parent component handle the situation
          this.$emit('logged-in')
        } else {
          const redirectedToForum = await tryForumSso(this.$route)
          if (!redirectedToForum) {
            await this.$router.push('/')
          }
        }
      } catch (e) {
        this.error = e.message
        console.debug(e.message, '\t', e.stack)
      }
    }
  }

}
</script>