<template>
  <div>
    <div>
      <img alt="email" style="width: 1rem" src="@/assets/email.png">
        {{ identity.email }}
    </div>
    <div>
      <router-link :to="`/changePassword?email=${identity.email}`">
        <i>Change password</i>
      </router-link>
    </div>
  </div>
  <div>
    <div v-if="!identity.verifiedAt">
      <img alt="warning" style="width: 1rem" src="@/assets/warning-yellow.png">
      <span>  Please check your email and verify this address</span>
      <p>Haven't you received a verification email?</p>
      <router-link :to="`/sendVerificationEmail?email=${identity.email}`">
        <button class="activeButton">Resend verification email</button>
      </router-link>
    </div>
    <div v-else>
      <img alt="warning" style="width: 1rem" src="@/assets/check-green.png">
      <span>  Verified</span>
    </div>
  </div>
  <div>
    <p>
      Created at: {{ identity.createdAt }}
    </p>
    <p>
      Last login at: {{ identity.lastLoginAt }}
    </p>
  </div>
</template>

<script>
export default {
  name: "Identity",
  props: ['identity']
}
</script>

<style scoped>

</style>