export const TITLE_ID = process.env.VUE_APP_TITLE_ID
export const WELCOME_MSG_KEY = 'motd'
export const ENTITY_TYPE_TITLE = 'Title'
export const LOCALE_OPTIONS = {
  // en: 'English',
  'en-US': 'English - US',
  // 'en-UK': 'English - UK',
  // es: 'Español',
  'es-AR': 'Español - AR',
  default: 'No locale', // this is fr
  // fr: 'Français',
}