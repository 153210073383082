<template>
  <div class="container">

    <div class="linkAccountPanel">
      <div v-if="done">
        <h1>Your password has been changed !</h1>
      </div>
      <form v-else class="login" @submit.prevent="changePassword">
        <h1>Change your password</h1>
        <div class="codePanel">
          <label>Email</label>
          <input required v-model="email" type="text"/>

          <label>Current password</label>
          <input required v-model="currentPassword" type="password"/>

          <label>New password</label>
          <input required v-model="newPassword" @change="validatePasswords" type="password"/>

          <label>Confirm your new password</label>
          <input required v-model="newPasswordConfirm" @change="validatePasswords" type="password"/>

          <button class="bigButton" :disabled="this.passwordsDontMatch" type="submit">enter</button>
        </div>

        <div class="error">
          <p>
            {{ error }}
          </p>
        </div>
      </form>
    </div>

  </div>
</template>

<script>
import { beanieSDK } from '@/vuex/store'

export default {
  data(){
    return {
      email: this.$route.query.email || '',
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      passwordsDontMatch: false,
      error: '',
      done: false
    }
  },

  methods: {
    async changePassword () {
      try {
        this.error = ''

        await beanieSDK.changePassword({
          email: this.email,
          currentPassword: this.currentPassword,
          newPassword: this.newPassword
        })

        this.done = true
      } catch (e) {
        this.error = e.message
      }
    },
    validatePasswords () {
      if (this.newPassword !== this.newPasswordConfirm) {
        this.error = 'Passwords don\'t match'
        this.passwordsDontMatch = true
      } else {
        this.error = ''
        this.passwordsDontMatch = false
      }
    }
  },

}
</script>