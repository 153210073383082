<template>
  <div>
    <img alt="console" style="width: 1rem" src="@/assets/console.png">
    {{ identity.displayName }}
    <p>Platform: {{ identity.platform }}</p>
  </div>
  <div>
    <p>
      Created at: {{ identity.createdAt }}
    </p>
    <p>
      Last login at: {{ identity.lastLoginAt }}
    </p>
  </div>
</template>

<script>
export default {
  name: "Identity",
  props: ['identity']
}
</script>

<style scoped>

</style>