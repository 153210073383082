<template>
  <RegisterWithEmailForm :error="this.error" @register-with-email-form="handleForm"/>
</template>

<script>
import RegisterWithEmailForm from "../RegisterMethods/RegisterWithEmailForm.vue"
import { beanieSDK } from "../../vuex/store"

export default {
  components: {
    RegisterWithEmailForm
  },

  data(){
    return {
      error: ""
    }
  },

  methods: {
    async handleForm(data) {
      try {
        const { account } = await beanieSDK.addEmailPassword(data)
        this.$store.dispatch('SET_ACCOUNT', { account })

        this.$emit('added-identity')
      } catch (e) {
        this.error = e.message
      }
    }
  }
}
</script>